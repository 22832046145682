:root {
    --primary-bg-color: #16161e;
    --primary-black-color:  rgba(0, 0, 0, 0);
    --primary-white-color: #fff;
    --primary-gray-color: #eee;
    --secondary-gray-color: #bbd2ca;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

body {
    background-color: var(--primary-bg-color) !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

iframe {
    border-radius: 15px;
    @media only screen and (max-width: 600px) {
        border-radius: 0px;
        height: 100% !important;
    }
}

input:-internal-autofill-selected {
    background-color: var(--primary-black-color) !important;
    font-size: 16px;       
}

.input-login {
    background-color: var(--primary-black-color) !important;
    font-size: 16px;       
}

.input-login:active {
    background-color: var(--primary-black-color) !important;
}

.MuiButtonBase-root a {
    text-decoration: none !important;
    color: var(--primary-white-color);
}

.MuiTableCell-alignLeft {
    padding-left: 0px !important;
}

.swal2-container.swal2-center {
    z-index: 99999;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: white;
    min-width: 43px;
    padding-left: 5px;
    padding-right: 5px;
}


.MuiPickersToolbar-toolbar {
    background-color: black !important;
  }
  
  .MuiToggleButton-root.Mui-selected {
    background-color: black !important;
    border-color: black;
    color: white !important;
  }
  

.js .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .inputfile + label {
    max-width: 80%;
    font-size: 1.25rem;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
  }
  
  .no-js .inputfile + label {
    display: none;
  }
  
  .inputfile:focus + label,
  .inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
  
  .inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
  }
  
  .inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
  }
  
  /* style 1 */
  
  .inputfile-1 + label {
    color: #f1e5e6;
    background-color: #d3394c;
  }
  
  .inputfile-1:focus + label,
  .inputfile-1.has-focus + label,
  .inputfile-1 + label:hover {
    background-color: #722040;
  }
  
  /* style 2 */
  
  .inputfile-2 + label {
    color: #d3394c;
    border: 2px solid currentColor;
  }
  
  .inputfile-2:focus + label,
  .inputfile-2.has-focus + label,
  .inputfile-2 + label:hover {
    color: #722040;
  }
  
  /* style 3 */
  
  .inputfile-3 + label {
    color: #d3394c;
  }
  
  .inputfile-3:focus + label,
  .inputfile-3.has-focus + label,
  .inputfile-3 + label:hover {
    color: #722040;
  }
  
  /* style 4 */
  
  .inputfile-4 + label {
    color: #d3394c;
  }
  
  .inputfile-4:focus + label,
  .inputfile-4.has-focus + label,
  .inputfile-4 + label:hover {
    color: #722040;
  }
  
  .inputfile-4 + label figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #d3394c;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;
  }
  
  .inputfile-4:focus + label figure,
  .inputfile-4.has-focus + label figure,
  .inputfile-4 + label:hover figure {
    background-color: #722040;
  }
  
  .inputfile-4 + label svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
  }
  
  /* style 5 */
  
  .inputfile-5 + label {
    color: #d3394c;
  }
  
  .inputfile-5:focus + label,
  .inputfile-5.has-focus + label,
  .inputfile-5 + label:hover {
    color: #722040;
  }
  
  .inputfile-5 + label figure {
    width: 100px;
    height: 135px;
    background-color: #d3394c;
    display: block;
    position: relative;
    padding: 30px;
    margin: 0 auto 10px;
  }
  
  .inputfile-5:focus + label figure,
  .inputfile-5.has-focus + label figure,
  .inputfile-5 + label:hover figure {
    background-color: #722040;
  }
  
  .inputfile-5 + label figure::before,
  .inputfile-5 + label figure::after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .inputfile-5 + label figure::before {
    border-top: 20px solid #dfc8ca;
    border-left: 20px solid transparent;
  }
  
  .inputfile-5 + label figure::after {
    border-bottom: 20px solid #722040;
    border-right: 20px solid transparent;
  }
  
  .inputfile-5:focus + label figure::after,
  .inputfile-5.has-focus + label figure::after,
  .inputfile-5 + label:hover figure::after {
    border-bottom-color: #d3394c;
  }
  
  .inputfile-5 + label svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
  }
  
  /* style 6 */
  
  .inputfile-6 + label {
    color: #d3394c;
  }
  
  .inputfile-6 + label {
    border: 1px solid #d3394c;
    background-color: #f1e5e6;
    padding: 0;
  }
  
  .inputfile-6:focus + label,
  .inputfile-6.has-focus + label,
  .inputfile-6 + label:hover {
    border-color: #722040;
  }
  
  .inputfile-6 + label span,
  .inputfile-6 + label strong {
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
  }
  
  .inputfile-6 + label span {
    width: 200px;
    min-height: 2em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
  }
  
  .inputfile-6 + label strong {
    height: 100%;
    color: #f1e5e6;
    background-color: #d3394c;
    display: inline-block;
  }
  
  .inputfile-6:focus + label strong,
  .inputfile-6.has-focus + label strong,
  .inputfile-6 + label:hover strong {
    background-color: #722040;
  }
  
  @media screen and (max-width: 50em) {
    .inputfile-6 + label strong {
      display: block;
    }
  }
  